<template lang="pug">
#transfer.checkout-wrapper
  //- h1.text-center CHECKOUT
  .main-wrapper(v-if="init")
    .container-lg.mb-5
      CheckoutStepperComponent(:step="source.step")
      b-row

        //- CONTENT
        b-col.mb-5.order-2.order-lg-1(md="12" lg="8" v-if="source.step === 1")
          .checkout-card
            .wrapper
              h1 Transfer Details
              .route
                .point
                  .wrapper {{ fromData.name }}
                .content
                  .wrapper
                    b-row
                      b-col(sm="6")
                        b-form-group(label="Departure: hotel name or address *" label-for="departure")
                          b-form-input#departure(type='text' size="lg" v-model="form.departure" placeholder="Hotel name or address")
                          has-error(:form='form' :field="`departure`")
                      b-col(sm="6")
                        b-form-group(label="Flight Code *"  label-for="flight_code")
                          b-form-input#flight_code(type='text' size="lg" v-model="form.flight_code" placeholder="Flight Code")
                          has-error(:form='form' :field="`flight_code`")
                      b-col(sm="6")
                        b-form-group(label="Date of transfer *" label-for="date")
                          //- b-form-input#date(type='text' v-model="form.date" placeholder="")
                          v-date-picker(
                            v-slot="{ inputValue, inputEvents }"
                            v-model="form.date"
                            mode="date"
                            is24hr
                            :popover="{ visibility: 'click' }"
                            :min-date="new Date()"
                            :masks="{L: 'DD-MM-YYYY'}"
                            :model-config="{ type: 'string', mask: 'YYYY-MM-DD' }"
                          )
                            b-form-input.bg-white(type="text" size="lg" :value="inputValue" v-on="inputEvents" readonly placeholder="Date")
                          has-error(:form='form' :field="`date`")
                      b-col(sm="6")
                        b-form-group(label="Time of transfer *" label-for="time")
                          b-form-input#time(type='time' size="lg" v-model="form.time" placeholder="")
                          has-error(:form='form' :field="`time`")
                .point
                  .wrapper {{ toData.name }}
              hr

              .round-trip
                .info
                  strong Round Trip
                  //- div 555 TRY
                b-form-checkbox(switch size="xlg" v-model="form.round_trip")

              .route.mt-3(v-if="form.round_trip")
                .point
                  .wrapper {{ toData.name }}
                .content
                  .wrapper
                    b-row
                      b-col(sm="6")
                        b-form-group(label="Departure: hotel name or address *" label-for="return-departure")
                          b-form-input#return-departure(type='text' size="lg" v-model="form.return_departure" placeholder="Hotel name or address")
                          has-error(:form='form' :field="`return_departure`")
                      b-col(sm="6")
                        b-form-group(label="Flight Code *" label-for="return_flight_code")
                          b-form-input#return_flight_code(type='text' size="lg" v-model="form.return_flight_code" placeholder="Flight Code")
                          has-error(:form='form' :field="`return_flight_code`")
                      b-col(sm="6")
                        b-form-group(label="Date of transfer *" size="lg" label-for="return-date")
                          //- b-form-input#return-date(type='text' v-model="form.return_date" placeholder="")
                          v-date-picker(
                            v-slot="{ inputValue, inputEvents }"
                            v-model="form.return_date"
                            mode="date"
                            is24hr
                            :popover="{ visibility: 'click' }"
                            :min-date="new Date()"
                            :masks="{L: 'DD-MM-YYYY'}"
                            :model-config="{ type: 'string', mask: 'YYYY-MM-DD' }"
                          )
                            b-form-input.bg-white(type="text" size="lg" :value="inputValue" v-on="inputEvents" readonly placeholder="Date")
                          has-error(:form='form' :field="`return_date`")
                      b-col(sm="6")
                        b-form-group(label="Time of transfer *" label-for="return-time")
                          b-form-input#return-time(type='time' size="lg" v-model="form.return_time" placeholder="")
                          has-error(:form='form' :field="`return_time`")
                .point
                  .wrapper {{ fromData.name }}

          .checkout-card.mt-5
            .wrapper.passenger
              h2 Passenger's Details
              b-form-group(label="Name and surname*" label-for="fullname")
                b-form-input#fullname(type='text' v-model="form.passenger_name" placeholder="")
                has-error(:form='form' :field="`passenger_name`")
              b-row
                b-col(lg="6")
                  b-form-group(label="E-mail*" label-for="email")
                    b-form-input#email(type='email' v-model="form.passenger_email" placeholder="")
                    has-error(:form='form' :field="`passenger_email`")
                b-col.desc(lg="6")
                  |We will send a booking confirmation, voucher, and reminder to this email address.
                b-col(lg="6")
                  b-form-group(label="Phone*" label-for="phone")
                    b-form-input#phone(type='text' v-model="form.passenger_phone" placeholder="")
                    has-error(:form='form' :field="`passenger_phone`")
                b-col.desc(lg="6")
                  |We need it for urgent communication with you. It must be available on the day of transfer.
              small By entering your contact information, you agree to our
                a(href="")  Privacy Policy.
          b-button.mt-3.btn-block(variant="success" @click="goToStepTwo()") Continue

        //- PAYMENT STEP
        b-col(md="12" lg="8" v-if="source.step === 2")
          .checkout-card.mb-4.p-4
            .wrapper.d-flex.flex-row.justify-content-between.align-items-center.w-100
              span Transfer Details
              strong.text-success.c-pointer(@click="source.step = 1") Edit

          .checkout-card.mb-5.p-4
            .wrapper
              b-form-group.payment-methods(label='Payment Method' v-slot='{ ariaDescribedby }')
                //- b-form-radio(v-model='paymentForm.payment_method' :class="paymentForm.payment_method === 'cash_to_driver' ? 'active' : '' " :aria-describedby='ariaDescribedby' name='payment_point' value='cash_to_driver') Cash to Driver
                b-form-radio(v-model='paymentForm.payment_method' :class="paymentForm.payment_method === 'at_hotel_check_in' ? 'active' : '' " :aria-describedby='ariaDescribedby' name='payment_point' value='at_hotel_check_in') At Hotel Check In
                b-form-radio(v-model='paymentForm.payment_method' :class="paymentForm.payment_method === 'by_payment_link' ? 'active' : '' " :aria-describedby='ariaDescribedby' name='payment_point' value='by_payment_link') By Payment Link
                b-form-radio(v-model='paymentForm.payment_method' :class="paymentForm.payment_method === 'direct_payment' ? 'active' : '' " :aria-describedby='ariaDescribedby' name='payment_point' value='direct_payment' disabled v-b-tooltip.hover title="Coming Soon") Online Payment (Cooming Soon)
                has-error(:form='paymentForm' field="payment_method")

              //- CREDIT CARD FORM
              //- InlineCreditCardField(v-model="paymentForm.card")
              b-button.mt-3.btn-block(variant="success" @click="pay()" :disabled="paymentForm.busy") {{paymentForm.payment_method === 'direct_payment' ? 'Pay' : 'Confirm'}}

        b-col(md="12" lg="12" v-if="source.step === 3")
          .checkout-card.mb-4.p-4
            .wrapper
              h1.text-left.text-uppercase.success-title.d-flex.align-items-center.justify-content-center
                img.big-icon(src="@/assets/icons/check-big.svg")
                | Success Transfer booking
              hr
              .order-details
                b-row
                  b-col.align-items-center.d-flex(lg="7")
                    b-row.no-gutters.w-100
                      b-col(lg="6")
                        .car-info(:class="packageData.color")
                          .title {{packageData.name}}
                          img.w-100(:src="`${apibase}/${packageData.cover}`")
                          .info
                            .pax
                              svg.mr-1(xmlns='http://www.w3.org/2000/svg' width='15' height='15' fill='none' viewbox='0 0 15 15')
                                path(fill='#6F7F8F' fill-rule='evenodd' d='M12.967 2.098c.488-.91.029-1.978-.78-1.978-.227 0-.455-.029-.69-.058-.729-.09-1.521-.189-2.568.538-1.198.831-1.36 3.104-.481 3.696.063.98.407 1.283.737 1.577.336.298.659.585.659 1.558 0 .977-.76 1.452-1.547 1.944l-.06.039c.634.477 1.138 1.072 1.138 2.02V15H15v-3.312c0-1.248-.914-1.837-1.777-2.394-.773-.499-1.504-.97-1.504-1.863 0-.89.311-1.18.64-1.487.371-.345.766-.713.766-1.984 0-.613 0-1.282-.158-1.862zM5.157 7.32l.001-.134L7.5 6.849s-.938-.49-.938-1.93v-1.44c0-.96-.937-1.92-2.343-1.92-1.407 0-2.344.96-2.344 1.92v1.44c0 1.44-.938 1.93-.938 1.93l2.342.336.002.134c0 .906-.731 1.385-1.504 1.89C.916 9.775 0 10.373 0 11.64V15h8.438v-3.36c0-1.267-.915-1.865-1.778-2.43-.772-.505-1.504-.984-1.504-1.89z' clip-rule='evenodd')
                              | {{ source.rate_stack.min }} - {{ source.rate_stack.max }}
                            .baggage.ml-2
                              svg.mr-1(xmlns='http://www.w3.org/2000/svg' width='18' height='19' fill='none' viewbox='0 0 18 19')
                                circle(cx='3' cy='18' r='1' fill='#6F7F8F')
                                circle(cx='15' cy='18' r='1' fill='#6F7F8F')
                                rect(width='6.6' height='4.6' x='5.7' y='.7' stroke='#6F7F8F' stroke-width='1.4' rx='1.3')
                                path(fill='#6F7F8F' d='M0 5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V5z')
                                path(fill='#fff' d='M3 14l3.5 2.02v-4.04L3 14zm10 0v.35a.35.35 0 0 0 .35-.35H13zm0-9l-2.02 3.5h4.04L13 5zm-6.85 9.35H13v-.7H6.15v.7zm7.2-.35V8.15h-.7V14h.7z')
                                circle(cx='8.999' cy='10' r='5.833' fill='#fff')
                                path(fill='#6F7F8F' d='M9 3a7 7 0 1 0 0 14A7 7 0 0 0 9 3zm1.457 10.849c-.36.142-.647.25-.862.324a2.274 2.274 0 0 1-.748.112c-.436 0-.776-.106-1.018-.32a1.03 1.03 0 0 1-.362-.81c0-.127.01-.257.027-.39.018-.132.047-.282.087-.45l.451-1.592c.04-.153.074-.298.101-.434.028-.136.04-.26.04-.375 0-.202-.041-.345-.125-.425-.085-.08-.244-.119-.482-.119-.116 0-.236.018-.358.054a5.095 5.095 0 0 0-.314.104l.12-.49c.294-.121.577-.224.847-.31s.524-.129.764-.129c.433 0 .768.106 1.003.314.234.21.352.482.352.816 0 .07-.008.191-.024.366a2.447 2.447 0 0 1-.09.48l-.45 1.588a4.49 4.49 0 0 0-.098.436 2.306 2.306 0 0 0-.043.371c0 .211.046.355.141.432.094.076.258.115.49.115.11 0 .233-.02.371-.058.138-.038.237-.071.3-.1l-.12.49zm-.08-6.447a1.07 1.07 0 0 1-.755.292 1.08 1.08 0 0 1-.759-.292.93.93 0 0 1-.315-.707.94.94 0 0 1 .315-.708c.211-.197.465-.295.759-.295s.547.098.755.295c.21.196.315.433.315.708a.93.93 0 0 1-.315.707z')
                              |{{ baggages }}
                        img.logo(src="@/assets/logo.png" style='width: 180px; margin-top: 20px;')

                      b-col(lg="6")
                        b-table-simple(small striped borderless v-if="source.order_stack")
                          tr
                            td Name:
                            td {{ source.order_stack.passenger_name }}
                          tr
                            td E-mail:
                            td {{ source.order_stack.passenger_email }}
                          tr
                            td Phone:
                            td {{ source.order_stack.passenger_phone }}
                          tr
                            td Order Date:
                            td {{ source.created_at }}
                          tr
                            td Note:
                            td {{ source.note }}

                  b-col(lg="5")
                    .wrapper.preview
                      .confirmation-code.mb-2
                        strong Confirmation Code
                        | {{ `EKNK-${source.id}` }}
                      .routes(v-for="(o, oIndex) in orders" :key="oIndex")
                        .points
                          .point {{ o.from }}
                          .point {{ o.to }}
                        .info
                          .dates Date: {{ o.service_date }}
                          .dates Time: {{ o.service_time }}
                          //- .departure Departure: {{ source.order_stack.departure }}
                        //- .price.text-success {{ order.price }} {{ source.currency }}
                      //- .routes(v-if="form.round_trip")
                        .points
                          .point {{ toData.name }}
                          .point {{ fromData.name }}
                        //- .price.text-success {{ order.price }} {{ source.currency }}

                    .checkout-card.totals.mt-3
                      strong TOTAL:
                      strong.text-success {{ order.total }} {{ source.currency }}
                    .d-flex.flex-row.justify-content-center.pt-3
                      b-button.mr-2(variant="outline-success" @click="showInvoiceWindow()") Voucher
                      b-button(variant="outline-success" @click="showInvoiceWindow()") Print

                  //- b-col(lg="12")
                    //- b-row.no-gutters
                    //-   //- b-col(lg="6")
                    //-   //-   .bottom-wrapper
                    //-   //-     img.logo(src="@/assets/logo.png")
                    //-   //-     .note
                    //-   //-       strong.text-uppercase NOTE:
                      //- b-col(lg="6")
                    //- b-button(variant="outline-success" @click="showInvoiceWindow()") Voucher
                    //- b-table-simple(small striped borderless v-if="source.order_stack")
                      tr
                        td Name
                        td {{ source.order_stack.passenger_name }}
                      tr
                        td E-mail
                        td {{ source.order_stack.passenger_email }}
                      tr
                        td Phone
                        td {{ source.order_stack.passenger_phone }}
                      tr
                        td Departure
                        td {{ source.order_stack.departure }}
                      tr
                        td Date
                        td {{ source.order_stack.date }}
                      tr
                        td Time
                        td {{ source.order_stack.time }}
                      //- ROUND TRIP
                      tr(v-if="source.order_stack.round_trip")
                        td
                          strong Round Trip
                      tr(v-if="source.order_stack.round_trip")
                        td Departure
                        td {{ source.order_stack.return_departure }}
                      tr(v-if="source.order_stack.round_trip")
                        td Date
                        td {{ source.order_stack.return_date }}
                      tr(v-if="source.order_stack.round_trip")
                        td Time
                        td {{ source.order_stack.return_time }}

        //- SIDEBAR
        b-col.order-1.order-lg-2(lg="4" v-if="source.step !== 3")
          .sidebar-wrapper
            .checkout-card.sidebar
              .header {{ packageData.name }}
              .wrapper.preview
                .routes
                  .points
                    .point {{ fromData.name }}
                    .point {{ toData.name }}
                  .price.text-success {{ order.price }} {{ source.currency }}
                .routes(v-if="form.round_trip")
                  .points
                    .point {{ toData.name }}
                    .point {{ fromData.name }}
                  .price.text-success {{ order.price }} {{ source.currency }}

            .checkout-card.totals.mt-3
              strong TOTAL:
              strong.text-success {{ order.total }} {{ source.currency }}
</template>

<script>
import axios from 'axios'
import Form from 'vform'
import CheckoutStepperComponent from '@/components/CheckoutStepperComponent'
import { CurrencyMixin } from '@/mixins/CurrencyMixin.js'
import CreditCardField from 'vue-credit-card-field/src/Components/CreditCardField.vue'
import InlineCreditCardField from 'vue-credit-card-field/src/Components/InlineCreditCardField.vue'
// import { CreditCardField, InlineCreditCardField } from 'vue-credit-card-field'

import Dinero from 'dinero.js'

const API_BASE_URL = process.env.VUE_APP_API_BASE_URL
const END_POINT = 'external-transfer/v1/transfer'

export default {
  name: 'CheckoutComponent',
  components: { CheckoutStepperComponent, CreditCardField, InlineCreditCardField },
  mixins: [CurrencyMixin],
  data: () => ({
    init: false,
    orders: [],
    source: [],
    form: new Form({
      uuid: null,
      departure: null,
      flight_code: null,
      date: null,
      time: null,
      round_trip: false,
      return_departure: null,
      return_flight_code: null,
      return_date: null,
      return_time: null,
      passenger_name: null,
      passenger_email: null,
      passenger_phone: null
    }),
    paymentForm: new Form({
      payment_method: null,
      card: null
    })
  }),
  computed: {
    apibase () {
      return API_BASE_URL
    },
    baggages () {
      // console.log(this.package.baggage_method)
      if (this.packageData.baggage_method === 'increase') {
        return this.source.rate_stack.max + this.packageData.baggage_value
      } else if (this.packageData.baggage_method === 'decrease') {
        return this.source.rate_stack.max - this.packageData.baggage_value
      }
      return this.source.rate_stack.max
    },
    fromData () {
      return this.source?.service_stack?.from
    },
    toData () {
      return this.source?.service_stack?.to
    },
    packageData () {
      return this.source?.packages_stack
    },
    order () {
      const vm = this

      const __costPrice = vm.source.rate_stack.cost
      const __originalPrice = vm.form.round_trip ? vm.source.service_stack.lowest_active_pax_two_way_price : vm.source.service_stack.lowest_active_pax_price
      const __price = vm.form.round_trip ? vm.source.packages_stack.two_way_price : vm.source.packages_stack.price
      const __costCurrency = vm.source.service_stack.cost_currency
      // const __packageCurrency = vm.source.packages_stack.currency

      // const providerCost = Dinero({ amount: vm.CM_FLATTEN(__costPrice), currency: __costCurrency, precision: 2 })
      // const originalPrice = Dinero({ amount: vm.CM_FLATTEN(__originalPrice), currency: vm.source.service_stack.currency, precision: 2 })
      // const price = Dinero({ amount: vm.CM_FLATTEN(__price), currency: vm.source.service_stack.currency, precision: 2 })
      let totalPrice = Dinero({ amount: vm.CM_FLATTEN(__price), currency: vm.source.service_stack.currency, precision: 2 })
      if (vm.form.round_trip) {
        totalPrice = totalPrice.multiply(2)
      }

      // const price = vm.form.round_trip ? vm.source?.packages_stack?.two_way_price : vm.source?.packages_stack.price
      const order = {
        providerCost: __costPrice,
        originalPrice: __originalPrice,
        price: __price,
        cost_currency: __costCurrency,
        total: totalPrice.toFormat('0.00')
        // providerCost: providerCost.toFormat('0.00'),
        // originalPrice: originalPrice.toFormat('0.00'),
        // price: price.toFormat('0.00')
      }
      return order
    }
  },
  mounted () {
    this.loadData()
    this.form.uuid = this.$route.params.uuid
    console.log(API_BASE_URL)
  },
  methods: {
    showInvoiceWindow () {
      const left = (screen.width / 2) - (1150 / 2)
      const top = (screen.height / 2) - (800 / 2)
      const invoiceUrl = `/voucher/${this.$route.params.uuid}`
      this.windowRef = window.open(
        invoiceUrl,
        '',
        `width=1150,height=800,left=${left},top=${top},menubar=0`
      )
      // this.windowRef.document.body.appendChild(this.$el)
    },
    loadData () {
      const vm = this
      const uuid = vm.$route.params.uuid
      axios.post(`${END_POINT}/pre-reservation/${uuid}`)
        .then((r) => {
          vm.source = r.data.success ? r.data.data.source : []
          vm.orders = r.data.success ? r.data.data.orders : []
          if (vm.source.order_stack) { vm.loadFormOldData() }
          vm.init = true
        })
    },
    loadFormOldData () {
      const vm = this
      vm.form.uuid = vm.source.order_stack.uuid
      vm.form.departure = vm.source.order_stack.departure
      vm.form.date = vm.source.order_stack.date
      vm.form.time = vm.source.order_stack.time
      vm.form.round_trip = vm.source.order_stack.round_trip
      vm.form.return_departure = vm.source.order_stack.return_departure
      vm.form.return_date = vm.source.order_stack.return_date
      vm.form.return_time = vm.source.order_stack.return_time
      vm.form.passenger_name = vm.source.order_stack.passenger_name
      vm.form.passenger_email = vm.source.order_stack.passenger_email
      vm.form.passenger_phone = vm.source.order_stack.passenger_phone
    },
    goToStepTwo () {
      const vm = this
      const uuid = vm.$route.params.uuid
      // axios.patch(`${END_POINT}/pre-reservation/${uuid}`)
      //   .then((r) => {
      //     console.log(r.data)
      //   })
      vm.form.submit('patch', `${API_BASE_URL}/${END_POINT}/pre-reservation/${uuid}`)
        .then((r) => {
          if (r.data.success) { this.source.step = 2 }
        }).catch((e) => {
          vm.$swal({ icon: 'warning', title: vm.$t('Please Check Form!'), toast: true, showConfirmButton: false, timer: 3000 })
        })
    },
    pay () {
      const vm = this
      const uuid = vm.$route.params.uuid
      // axios.patch(`${END_POINT}/pre-reservation/${uuid}`)
      //   .then((r) => {
      //     console.log(r.data)
      //   })
      vm.paymentForm.submit('post', `${API_BASE_URL}/${END_POINT}/checkout/${uuid}`)
        .then((r) => {
          if (r.data.success) {
            console.log('go To 3')
            vm.source = r.data.data.source
            vm.orders = r.data.data.orders
          }
        }).catch((e) => {
          vm.$swal({ icon: 'warning', title: vm.$t('Please Check Form!'), toast: true, showConfirmButton: false, timer: 3000 })
        })
    }
  }
}
</script>

<style lang="scss">

.payment-methods{
  .custom-control.custom-radio{
    border: 1px solid #e4e7ea;
    border-radius: 8px;
    padding: 10px 10px 10px 40px;
    width: 100%;
    margin-bottom: 5px;
    font-weight: bold;
    background-color: #f6f7f8;
    .custom-control-label{
      width: 100%;
    }
    &.active{
      background-color: #edffe4;
    }
  }
  .custom-control-input:checked~.custom-control-label::before{
    border-color: #ff8001;
    background-color: #ff8001;
  }
}

.checkout-wrapper{
  .sidebar-wrapper{
    @media (min-width: 640px){
      // STICKY
      position: sticky;
      top: 20px;
    }
  }
  .checkout-card{
    border-radius: 8px;
    padding: 16px 16px 24px;
    background-color: #fff;
    -webkit-box-shadow: 0 4px 8px 0 rgb(0 0 0 / 11%);
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 11%);
    h1,h2{
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      margin-bottom: 15px;
    }
    .route{
      position: relative;
      .point{
        position: relative;
        .wrapper{
          margin-left: 20px;
          margin-bottom: 10px;
          background-color: #f6f7f8;
          display: inline-flex;
          padding: 0px 15px 0px 20px;
          border-radius: 0 5px 5px 0;
          font-weight: bold;
          height: 30px;
          align-items: center;
        }
        &::before{
          content: " ";
          position: absolute;
          left: 0;
          border-radius: 20px;
          width: 30px;
          height: 30px;
          background-color: #e4e7ea;
          z-index: 1;
        }
        &::after{
          content: " ";
          position: absolute;
          left: 9px;
          top: 9px;
          width: 12px;
          height: 12px;
          background-color: #ff8001;
          border-radius: 50%;
          z-index: 2;
        }
      }
      .content{
        position: relative;
        .wrapper{
          margin-left: 40px;
        }
        &::before{
          content: " ";
          position: absolute;
          left: 14px;
          width: 2px;
          background-color: #ff8001;
          height: calc(100% + 30px);
          top: -20px;
          z-index: 3;
        }
      }
    }
    .round-trip{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    .wrapper.passenger{
      .desc{
        display: flex;
        align-items: flex-start;
        margin-top: 30px;
        font-size: 13px;
      }
    }
    &.totals{
      display: flex;
      justify-content: space-between;
      padding: 16px;
      background: linear-gradient(to right, #ffffff, #27a745 80%) !important;
      .text-success{
        color: white !important;
      }
    }
    &.sidebar{
      .header{
        margin: -16px -16px 10px -16px;
        padding: 10px;
        background-color: #6f7f8f;
        border-radius: 8px 8px 0px 0px;
        color: white;
      }
    }
  }

  .routes{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #f6f7f8;
    border-radius: 8px;
    margin-bottom: 10px;
    padding: 10px;
    .points{
      .point{
        position: relative;
        line-height: 25px;
        padding-left: 10px;
        &:not(:last-child)::before{
          content: "";
          position: absolute;
          width: 1px;
          display: block;
          background-color: #b6bec7;
          top: 10px;
          left: 0px;
          height: 25px;
        }
        &::after{
          content: "";
          position: absolute;
          width: 5px;
          height: 5px;
          display: block;
          background-color: #b6bec7;
          border-radius: 50%;
          top: 10px;
          left: -2px;
        }
      }
    }
    .price{

    }
  }

  // Switch Box - Controls
  .b-custom-control-xlg{
    position: relative;
    width: 56px;
    height: 40px;
    padding-left: 36px;
    .custom-control-label{
      &::before{
        width: 56px;
        height: 32px;
        border-radius: 16px;
      }
      &::after{
        transform: translateX(0);
        top: calc(4px + 2px);
        left: calc(-36px + 2px);
        width: calc(32px - 4px);
        height: calc(32px - 4px);
        border-radius: 50%;
      }
    }
     .custom-control-input{
       &:focus:not(:checked)~{
         .custom-control-label::before{
          border: none;
          box-shadow: 0 0 0 0.2rem rgba(51, 166, 91, 0.226);
         }
       }
        &:checked~{
          .custom-control-label::before{
            border-color: #ff8001;
            background-color: #ff8001;
          }
          .custom-control-label::after{
            transform: translateX(1.55rem);
          }
        }
      }
  }
}

.invalid-feedback {
  display: block;
}

.success-title{
  text-transform: uppercase !important;
  font-size: 40px !important;
  color: #ff8002 !important;
  line-height: 40px !important;
  .big-icon{
    width: 60px;
    height: 60px;
    margin-right: 15px;
  }
}

.checkout-card{
  .bottom-wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 15px;
    img.logo{
      width: 100%;
      max-width: 250px;
    }
    .note{
      width: 100%;
      border: 1px solid #ff8002;
      border-radius: 5px;
      min-height: 75px;
      padding: 5px;
    }
  }
  .confirmation-code{
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    strong{
      font-weight: 600;
      color: #ff8001
    }
  }
  .car-info{
    background-image: linear-gradient(to top,#ebeff2,#dae4eb 63%);
    border-radius: 8px;
    margin-right: 10px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .info{
      background-color: white;
      padding: 2px 5px;
      margin-top: 5px;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .title{
      font-size: 25px;
      text-align: center;
      font-weight: bold;
    }
    img{
      max-width: 200px;
    }
    .baggage{

    }
    &.orange{
      background: linear-gradient(to top, #ff8002, #f7c594 63%) !important;
    }
    &.yellow{
      background: linear-gradient(to top, #ff8001, #fdcb9a 63%) !important;
    }
    &.silver{
      background: linear-gradient(to top, #a7a9ac, #efefef 63%) !important;
    }
    &.gold{
      background: linear-gradient(to top, #a09167, #f7e5b3 63%) !important;
    }
  }
}

</style>
