<template lang="pug">
.row
  .col-lg-12.col-md-12
    .knk-progress
      .knk-progress__content
        .knk-progress-step._active
          .top-line
            span.knk-progress-step__step
              | 1.
            span.knk-progress-step__label
              | Transfer booking
          .bottom-line
            span.progress-circle
            .progress-divider-holder
              .progress-divider
              .progress-divider-active(v-if="step >= 1" :class="step > 1 ? '_completed' : ''")
        .knk-progress-step(:class="step >= 2 ? '_active' : ''")
          .top-line
            span.knk-progress-step__step
              | 2.
            span.knk-progress-step__label
              | Payment
          .bottom-line
            span.progress-circle
            .progress-divider-holder
              .progress-divider
              .progress-divider-active(v-if="step >= 2" :class="step > 2 ? '_completed' : ''")
              //
        .knk-progress-step(:class="step >= 3 ? '_active' : ''")
          .top-line(style="right:0;")
            span.knk-progress-step__step
              | 3.
            span.knk-progress-step__label
              | Confirmation
          .bottom-line
            span.progress-circle
            .progress-divider-holder
              .progress-divider
              //
  //- .col-lg-4.col-md-12
    //- .stepper-trust-wrapper
      .knk-save-payment
        .knk-save-payment__wrapper
          .knk-save-payment__left
            img.knk-save-payment__icon._exclude(
              src="/static/front/bundle-js/ota-checkout/3.3/img/exclude-gray.e1ce80e8.svg",
              alt="exclude-gray",
              width="29",
              height="31"
            )
          .knk-save-payment__right
            .knk-save-payment__text
              .knk-tooltip(tabindex="0")
                span
                  | Secure payment
            .knk-save-payment__icons
              .knk-save-payment__icon._mastercard
                img(
                  src="/static/front/bundle-js/ota-checkout/3.3/img/mastercard.1a05e698.svg",
                  alt="master-card"
                )
              .knk-save-payment__icon._visa
                img(
                  src="/static/front/bundle-js/ota-checkout/3.3/img/visa.6ef7dfda.svg",
                  alt="visa"
                )
</template>

<script>
export default {
  name: 'CheckOutStepperComponent',
  components: { },
  props: {
    step: { type: Number, default: 1 }
  },
  data: () => ({}),
  computed: {},
  mounted () {},
  methods: {}
}
</script>

<style lang="scss">

.knk-progress-step {
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin-right: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  .top-line {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 8px;
    color: #b6bec7;
    font-size: 10px;
    font-weight: 600;
    line-height: 1;
  }
  .bottom-line {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
  }
  .progress-circle {
    display: inline-block;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 12px;
    height: 12px;
    margin-right: 8px;
    background-color: transparent;
    border: 2px solid #b6bec7;
    border-radius: 50%;
  }
  .progress-divider-holder {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 2px;
  }
  .progress-divider {
    height: 2px;
    background-color: #b6bec7;
  }
  .progress-divider-active {
    height: 2px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: none;
    width: 50%;
    background-color: #ff8001;
    -webkit-transition: width 0.25s ease-in-out;
    transition: width 0.25s ease-in-out;
  }
  .progress-divider-active._completed {
    width: 100%;
  }
  &:last-child {
    -webkit-box-flex: 0;
    -ms-flex: 0;
    flex: 0;
    margin-right: 0;
    .top-line {
      position: absolute;
      top: 0;
    }
    .progress-circle {
      margin: 0;
    }
    .progress-divider-holder {
      display: none;
    }
  }
}
.knk-progress-step__step {
  margin-right: 2px;
}
.knk-progress-step._active {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  .top-line {
    color: #373f47;
  }
  .progress-circle {
    background-color: #ff8001;
    border-color: #ff8001;
  }
  .progress-divider-active {
    display: block;
  }
}
.knk-progress {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 17px 0;
}
.knk-progress__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.save-payment-tooltip-content {
  font-size: 12px !important;
  line-height: 16px;
  text-align: left;
}
.save-payment-secure-icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 22px;
  margin-top: 16px;
}
.save-payment-secure-icon {
  img {
    max-width: 100%;
  }
  &:not(:last-child) {
    margin-right: 16px;
  }
}
.save-payment-secure-icon._pci-dss {
  width: 47px;
  height: 19px;
}
.knk-save-payment {
  padding-top: 6px;
}
.knk-save-payment__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.knk-save-payment__left {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 30px;
  flex: 0 0 30px;
  margin-right: 10px;
}
.knk-save-payment__right {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
.knk-save-payment__text {
  margin-bottom: 4px;
  font-size: 8px;
  line-height: 1.2;
  color: #373f47;
  font-weight: 600;
  span {
    border-bottom: 1px dotted #373f47;
  }
}
.knk-save-payment__icons {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.knk-save-payment__icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.knk-save-payment__icon._mastercard {
  margin-right: 6px;
}
.checkout-stepper {
  height: 60px;
  margin-bottom: 18px;
}
.checkout-stepper__inner._is-fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 12;
  width: 100%;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 16px 0 rgba(55, 63, 71, 0.15);
  box-shadow: 0 2px 16px 0 rgba(55, 63, 71, 0.15);
}
.checkout-stepper__logo {
  display: none;
  position: absolute;
  z-index: 1;
  top: 22px;
  left: 40px;
  width: 120px;
  height: 20px;
  img {
    max-width: 100%;
  }
}
.stepper-trust-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 10px 0;
}
@media (min-width:1460px) {
  .checkout-stepper__logo {
    display: block;
  }
}
</style>
